<section id="about">
    <div class="container">
        <h2><strong>{{'about.title' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-12" data-aos="fade-right">
                <picture>
                    <img src="../../assets/images/foto.jpg" alt="foto di profilo" class="img-fluid">
                </picture>
                <div class="d-flex justify-content-center">
                    <a class="btn-social-media" href="https://www.linkedin.com/in/fabio-petrone-221566214/" rel="tooltip"
                        target="_blank" title="{{'about.followMeOn' | translate}} Linkedin">
                        <i class="fa fa-linkedin fa-lg "></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-8  col-md-12 pt-4 pt-lg-0" data-aos="fade-left">
                <h3>{{'about.jobTitle' | translate}}</h3>
                <p class="font-italic">
                    {{'about.introductionOfMe' | translate}}
                    <br>{{'about.passionateAboutDevelopment' | translate}} <b>Front-End</b> {{'about.and' | translate}} <b>Back-End</b>.
                </p>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <fa-icon [icon]="mapMarker"></fa-icon><strong> {{'about.mobility' | translate}}</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="building"></fa-icon><strong> Genova, Italia</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="email"></fa-icon><strong><a href="mailto:fabiopetrone00@icloud.com">
                                    fabiopetrone00@icloud.com</a></strong>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <fa-icon [icon]="birthday"></fa-icon><strong> 23 {{'about.years' | translate}}</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="badge"></fa-icon><strong> {{'about.graduation' | translate}}</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="home"></fa-icon><strong> {{'about.myResearch' | translate}} </strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <p>
                    {{'about.researchDescription' | translate}}
                </p>
            </div>
        </div>
    </div>
</section>