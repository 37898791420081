export const projectsEn = [
    {
        id:"teamTraveler",
        name: "Paranoia Games",
        technologies: 'Unreal Engine',
        images: [
            {
                title:'Trip',
                src: "assets/images/paranoia.jpg",
            },
        ],
        url: "https://github.com/ParanoiaGame-v1-0/Paranoia",
        detail:"The application will allow you to organize group trips easily and quickly. Vote for dates, transport, accommodation or even activities. Thanks to this <a title='Download on Google Play' href='https://play.google.com/store/apps/details?id=team.traveler.teamtraveler'>application</a> no more problems with choices or disagreements.",
        codeSourceUrl:'https://github.com/ParanoiaGame-v1-0/Paranoia',
        detailIsDisplayed: false,
    },
    {
        id:"ParkIT",
        name: "ParkIT",
        technologies: 'Angular, .NET',
        images: [
            {
                title:'Marketing campaigns',
                src:   "assets/images/parking.jpg",
            },      
        ],
        url:'#portfolio',
        detail:"<p>Mautic is an open source automatic marketing tool</p> <p>The application allows you to have dynamic and detailed reports, to analyze individual campaign trends.<br> A visually user-friendly dashboard gives you a unique quick view of the performance of: your digital campaigns, assets, and forms. </p> <p>The application is available in French and English.</p>",
        codeSourceUrl:'https://github.com/GaBs-Team/ParkIT',
        detailIsDisplayed: false,
    },
    {
        id:"Ticketing",
        name: "Ticketing",
        technologies: 'Angular, .NET',
        images: [
            {
                title:'Pokedex',
                src:   "assets/images/ticketing.jpg",
            },       
        ],
        url:'#portfolio',
        detail:"<p>Display of the details of pokemon and their trainers.</p> <p>The two micro-service application contains: pokemon management and trainer management, which exposes a REST/JSON communication channel.<br>The 3rd WEB-UI micro-service connects to both micro-services.</p>",
        codeSourceUrl:'https://github.com/GaBs-Team/Easy-Ticketing',
        detailIsDisplayed: false,
    },
    {
        id:"AMNails",
        name: "AMNails",
        technologies: 'Angular, Json-server',
        images: [
            {
                title:'AMNails',
                src:   "assets/images/prenotazione.png",
            },
        ],
        url:'https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640',
        detail:"<p> CV in the form of a responsive WEB site that I put in open-source on GitHub accompanied by a publication of a <a title='See the article' href='https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640' target='_blank'>article</a> on medium. </p>",
        codeSourceUrl:'https://github.com/GaBs-Team/AMNails',
        detailIsDisplayed: false,
    },
];

