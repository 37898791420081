export const locale = {
    lang: 'en',
    data: {
        'about': {
            'title': 'About',
            'jobTitle': 'Web application developer',
            'introductionOfMe': 'Interested in new technologies and working methods. I like to come up with ideas that permit to improve business processes.',
            'passionateAboutDevelopment':'Passionate about development',
            'mobility': 'Mobile throughout Nord-Italy',
            'years': 'Years',
            'graduation': "High school graduation",
            'myResearch': 'CDI contract search',
            'researchDescription': 'I am looking for an innovative company that seeks to better meet the needs of the customer, from his idea to the final delivery of his project, or participate in the improvement of an existing project.',
            'and':'and',
            'followMeOn':'Follow me on',
        },
        'contact': {
            'contactMe': 'Contact Me',
            'notHesitateToContactMe': 'Do not hesitate to contact me',
            'identify': 'Identify',
            'enterIdentify': 'Enter your identify',
            'email': 'Email',
            'enterEmail': 'Enter your email',
            'object': 'Object',
            'enterObject': 'Enter object',
            'message': 'Message',
            'send': 'Send',
        },
        'education': {
            'title': 'Curriculum Vitae',
            'eductionTitle': 'Education',
            'experiencesTitle': 'Experiences',
            'centerOfInterest': 'Center of interest',
            'sport': 'Sport',
            'trips': 'Trips',
            'boardGames': 'Board games',
            'languages': 'Languages',
            'fluent': 'native language',
            'goodLevel': 'Good level',
            'french': 'Italian',
            'english': 'English',
            'masterDegreeTwo': "Develop Full Stack Apps with Angular and ASP Net core",
            'masterDegreeTwoDesc': "Certificate of completion (163 lessons)",
            'masterDegreeOne': "Microsoft azure foundamentals",
            'masterDegreeOneDesc': "General use azure",
            'bachlorDegree': "Secondary education graduation",
            'bachlorDegreeDesc': "I.T.I.S Calvino",
            'university': 'IT expert',
            'mission':'Mission',
            'technologies':'Technologies',
            'detail':'Detail',
            'tripsCountries':'France, Germany, Swiss',
            'boardGamesPlayed':'Checkers and dominoes game, Among US, Ludo, ..etc',
            'years':'years'
        },
        'header': {
            'home': 'Home',
            'about': 'About',
            'skills': 'Skills',
            'references': 'References',
            'contactMe': 'Contact me',
        },
        'home': {
            'iAmDeveloper': 'I am developer',
        },
        'projects': {
            'title': 'Portfolio',
            'projectDetail':'Project detail',
            'sourceCode':'Source code',
        },
        'references': {
            'title': 'References',
        },
        'skills': {
            'title': 'Skills',
            'technologies': 'Technologies',
            'tools': 'Tools',
            'methodologies': 'Methodologies'
        },
    }
}