import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../service/translation-loader.service';
import { locale as english } from '../shared/i18n/en';
import { locale as french } from '../shared/i18n/fr';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  skills:any={
    technologies:[{name:"HTML5, CSS",percent:95,remark:'excellent2'}, {name:"C#",percent:70,remark:'good'},{name:"Typescript",percent:90,remark:'excellent'},{name:"SQL",percent:70,remark:'good'}, {name:"JavaScript, BootStrap, Angular", percent:90,remark:'excellent'}],
    tools:[{name:"Office, Azure",percent:90,remark:'excellent'},{name:"Git",percent:50,remark:'average'},{name:"Linux, Windows",percent:95,remark:'excellent2'},{name:"Sql Server Management Studio",percent:90,remark:'excellent'},{name:"Visual Studio Code, Visual Studio",percent:95,remark:'excellent2'}],
    methodologies:[{name:"Autonomy",percent:95,remark:'excellent2'},{name:"Lifelong learning",percent:90,remark:'excellent'},{name:"Problem solving",percent:90,remark:'excellent'},{name:"Teamwork",percent:100,remark:'excellent3'},{name:"Ability to adapt",percent:95,remark:'excellent2'}]
  };

  constructor(private _translationLoaderService: TranslationLoaderService) {
    this._translationLoaderService.loadTranslations(english, french);
  }

  ngOnInit(): void {
  }

}

    
    
  
