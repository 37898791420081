<section id="contact">
    <div class="container mb-5">
        <h2><strong >{{'contact.contactMe' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row"  data-aos="fade-up"
        data-aos-anchor-placement="top-center">
            <div class="col-md-3 col-lg-3">
                <div class="contact-info">
                    <img src="assets/images/contact-image.png" alt="image" />
                    <h4>{{'contact.notHesitateToContactMe' | translate}}</h4>
                </div>
            </div>
            <form style="width: 70%;" name="contactform" (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
            <div class="col-md-9">
                <div class="contact-form">
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="email">{{'contact.email' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="email" placeholder="{{'contact.enterEmail' | translate}}" name="email" ngModel #email="ngModel">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="object">{{'contact.object' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="object" placeholder="{{'contact.enterObject' | translate}}"
                               name="name" ngModel #name="ngModel">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="comment">{{'contact.message' | translate}}</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" rows="5" id="comment" name="messages" ngModel #messages="ngModel"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-10">
                            <button type="submit" class="btn btn-default" [disabled]="!contactForm.valid">{{'contact.send' | translate}} </button>
                        </div>
                    </div>
                
                </div>  
              
            </div>
             </form>
        </div>
        
    </div>
</section>