<div class="page-content">
    <app-header></app-header>
    <app-home></app-home>
    <app-about></app-about>
    <app-skills></app-skills>
    <app-curriculum-vitae></app-curriculum-vitae>
    <app-projects></app-projects>
    <app-contact></app-contact>
    <br>
    <br>
    <footer class="footer">
        <p> Copyright © 2023-<span>{{currentYear}}</span> By Fabio Petrone.</p>
    </footer>
</div>