export const experiencesEn = [
    {
        title: "Full-Stack Developer",
        company: 'Sirius Ship Management',
        period: "2022 - present",
        technologies: 'C#, .NET, Angular, Angular Materials, Sql, PrimeNg',
        missions: [
            "Design, development, testing and release of a new application for the management of seafarers on board, ashore and ships under management (In progress).",
            "Create internal web applications to improve the quality of the company while minimizing the effort of employees in performing their tasks.",
            "Installation of new applications to administer and operate on seafarers.",
            "Management of the network infrastructure.",
            
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Network System Operator",
        company: 'Digitmode',
        period: "2022 (3 months)",
        technologies: 'Point-of-sale system',
        missions: [ 
            "Management of existing pos systems",
            "Large-scale implementation of new point-of-sale.",
            "Updates of the point-of-sale present throughout Italy. (Arcaplanet)",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Network System Operator",
        company: 'Leo`s Computer',
        period: "2021-2022 (4 months)",
        technologies: 'Azure, Linux Server, Windows Server',
        missions: [
            "Implementation and management of network structures.",
            "Using the linux operating system for data recovery, data backup, access management.",
        ],
        detailIsDisplayed: false,
    },
];

